<div class="publication">
    <div class="images">
        <div class="image">
            <img [src]="material?.url" />
            <div class="cart-extract">
                <div *ngIf="material?.bookExtractUrl" (click)="openPdf(material?.bookExtractUrl!)" class="extract">
                    <a target="_blank"><img src="./assets/images/materials/book_extract/beolvasas_icon.jpg" /> </a>
                    <span>Beleolvasó</span>
                </div>
                <div *ngIf="material?.isAvailable && material?.routerLink !== 'morte_taska'" class="shopping-cart-container">
                    <div *ngIf="!material?.hasPreorder" class="shopping-cart">
                        <button *ngIf="material?.quantityOfSupply !== 0" (click)="addToCart(material!)">KOSÁRBA</button>
                        <div *ngIf="material?.quantityOfSupply === 0" class="inactive">
                            <button class="inactive-button" [ngStyle]="{ opacity: '0.5', cursor: 'auto' }">
                                {{ 'shoppingCart.putIntoCart' | translate }}
                            </button>
                            <span>Elfogyott</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="material?.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(material!)" class="preorder">ELŐRENDELÉS</button>
                </div>
                <div *ngIf="material?.bookExtractUrl" (click)="openPdf(material?.bookExtractUrl!)" class="extract-desktop">
                    <a target="_blank"><img src="./assets/images/materials/book_extract/beolvasas_icon.jpg" /> </a>
                    <span>Beleolvasó</span>
                </div>
            </div>
        </div>
        <div class="sinapsis">
            <div class="content">
                <div class="author-title">
                    <h2 class="title">{{ material?.title }}</h2>
                    <span>{{ material?.subtitle }}</span>
                    <h2 class="author">{{ material?.author }}</h2>
                </div>
                <div class="shopping-cart-containerr">
                    <div class="shopping-cartt">
                        <!-- <h3 *ngIf="!material?.discounted">{{ material?.price | currency : 'Ft' : 'symbol' : '1.0-0' }}</h3>
                        <s *ngIf="material?.discounted">{{ material?.price | currency : 'Ft' : 'symbol' : '1.0-0' }}</s>
                        <h3 *ngIf="material?.discounted">{{ material?.discountPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</h3> -->
                        <span *ngIf="material?.oldPrice !== material?.originalPrice || material?.hasPreorder">
                            Ár a könyvön: {{ material?.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </span>
                        <h3 *ngIf="material?.originalPrice! > 0 && material?.webshopPriceActive">
                            Online ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </h3>
                        <h3 *ngIf="material?.originalPrice! > 0 && material?.discountPriceActive">
                            Akciós ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </h3>
                        <h3 *ngIf="material?.hasPreorder" [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                            Előrendelői ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </h3>
                        <div *ngIf="material?.isAvailable" class="shopping-cart-container-mobile">
                            <div *ngIf="!material?.hasPreorder" class="shopping-cart-mobile">
                                <button *ngIf="material?.quantityOfSupply !== 0" (click)="addToCart(material!)">KOSÁRBA</button>
                                <div *ngIf="material?.quantityOfSupply === 0" class="inactive">
                                    <button class="inactive-button" [ngStyle]="{ opacity: '0.5', cursor: 'auto' }">
                                        {{ 'shoppingCart.putIntoCart' | translate }}
                                    </button>
                                    <span>Elfogyott</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="material?.hasPreorder" class="shopping-cart-mobile">
                            <button (click)="addToCart(material!)" class="preorder-mobile">ELŐRENDELÉS</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="material?.routerLink !== 'morte_taska'" class="parameter-box">
                    <!-- <div *ngIf="material?.hasPreorder" class="preorder-text">
                        <div class="preorder">
                            <p>{{ preOrderData }}</p>
                        </div>
                    </div> -->
                    <div class="param-box">
                        <p *ngIf="material?.serieId !== null">
                            {{ 'materials.serie' | translate }} {{ serie.serieName }} {{ material?.serieNumber }}.
                        </p>
                        <p *ngIf="material?.translator">{{ 'materials.translator' | translate }} {{ material?.translator }}</p>
                        <p *ngIf="material?.illustrator">{{ 'materials.illustrator' | translate }} {{ material?.illustrator }}</p>
                        <br />
                        <p *ngIf="!material?.isAvailable">
                            {{ 'materials.expectedPublicationDate' | translate }} {{ material?.available | date }}
                        </p>
                        <p *ngIf="material?.isAvailable">{{ 'materials.publishedBook' | translate }} {{ material?.available | date }}</p>
                        <p>{{ 'materials.bond' | translate }} {{ material?.bond }}</p>
                        <p>{{ 'materials.volume' | translate }} {{ material?.content }}</p>
                        <p>{{ 'materials.size' | translate }} {{ material?.dimension }}</p>
                        <p>{{ 'materials.isbn' | translate }} {{ material?.isbn }}</p>
                    </div>

                    <div *ngIf="packageOffer.length > 0" routerLink="/specialoffer/{{ packageOffer[0].routerLink }}" class="package-offer">
                        <div class="christmas-text">
                            <h3>Csomagban, kedvezménnyel!</h3>
                        </div>
                        <img src="{{ packageOfferElement.imageUrl }}" />
                        <div [ngStyle]="{ 'padding-top': '16px' }" class="package-name">
                            <b>{{ packageOfferElement.packageName }} csomag</b>
                        </div>
                        <div [ngStyle]="{ 'padding-top': '8px' }" class="package-price">
                            <span>Akciós ár: {{ packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }} </span>
                        </div>
                    </div>
                </div>
                <div class="text-data-container">
                    <div *ngIf="material?.routerLink !== 'morte_taska'" class="text-data">
                        <div [innerHTML]="material?.txt!"></div>
                    </div>
                    <!-- <div *ngIf="material?.orderNumber === cthulhuOrderNumber" class="text-data">
                        <span class="first-span">
                            Boston, 1926. Francis Thurston átlapozza azokat az iratokat, amelyeket nemrégiben rejtélyes körülmények közt
                            elhunyt nagybátyja hagyott hátra. Hamarosan felfedez egy kultuszt, amely ősi és kimondhatatlan borzalmaknak
                            hódol. Titokzatos gyilkosságok, véráldozatok a louisianai mocsarak mélyén, művészek, akik beleőrülnek
                            rémálomszerű vízióikba és egy tengerből kiemelkedő félelmetes város. Thurston lépésről lépésre haladva rájön,
                            hogy rokona túl közel került az igazsághoz:
                        </span>
                        <span>
                            egy sötét kultusz hívei fel akarják ébreszteni az istenükként bálványozott alvó Cthulhut, hogy őrületbe és
                            pusztulásba taszítsa az egész világot. A csillagok állása kedvező. Közel a vég?</span
                        ><br />
                        <span>
                            H. P. Lovecraft Cthulhu hívása című műve, amely először 1928-ban jelent meg, az amerikai horrorirodalom egyik
                            legnagyobb klasszikusa. Cthulhu, egy a csillagok közül érkezett ősi lény, aki a tenger fenekén mély álomba
                            merülve várakozik rettenetes visszatérésére, az egész Lovecraft alkotta mitológia szimbólumává vált. François
                            Baranger illusztrátort, aki korábban filmekkel és játékokkal is foglalkozott, már korán lenyűgözték Lovecraft
                            lényei és a fantázia sötét bugyraiból származó látomásai. Ebben a könyvben arra vállalkozott, hogy festményein
                            elevenítse meg Lovecraft legikonikusabb elbeszélését. </span
                        ><br />
                        <span class="italic"
                            >„François Baranger művészete elegáns józanságról árulkodik; egy hátborzongató és gigantikus szépségbaljós
                            hatású és látványos víziói ezek. Nincs kétségem afelől, hogy magát Lovecraftot is megörvendeztette volna.”</span
                        >
                        <span class="last-span"> John Howe </span>
                    </div> -->
                    <!-- <div *ngIf="material?.orderNumber === eurotrashOrderNumber" class="text-data">
                        <span class="first-span">
                            Példaértékű-e Európa? Európa korszerűtlen intézményrendszerének, gazdasági növekedése megtorpanásának, erkölcsi
                            irányvesztésének és kulturális önfeladásának terhe alatt nyög. Mégis szép számmal akadnak olyan „eurofil”
                            amerikai politikusok, tudósok és újságírók, akik arra biztatják Amerikát, hogy vegyen példát az Atlanti-óceán
                            túloldalán található országok egészségügyéről, jóléti intézkedéseiről, bevándorláspolitikájáról és még sok
                            minden másról. Miközben a szerző arról kívánja meggyőzni amerikai olvasóit, hogy rossz helyen keresik a
                            megoldást, akik erre buzdítanak, tükröt tartva európai olvasóinak vitatkozik az „euroblabla” jól ismert
                            állításaival is.
                        </span>
                        <span>
                            Mit jelent a föderális és a szuperállam közötti különbség? Több vagy kevesebb szabályozásra van-e szüksége a
                            gazdaságnak? Milyen mértékben támogassa az állam az egészségügyet? Segítségnyújtás vagy orvos által támogatott
                            öngyilkosság-e az eutanázia? Eltűnik-e a modern társadalmakból a vallásos hit? S legfőképpen: az Atlanti-óceán
                            melyik oldalán találhatunk problémáinkra jobb megoldásokat? David Harsanyi provokatív stílusban bontja le az
                            európai felsőbbrendűség mítoszait, és igyekszik választ találni ezekre a hazai és Európa-szerte zajló vitákból
                            is ismerős, nyugtalanító kérdésekre is.</span
                        ><br />

                        <span
                            >„Ha Európa jogilag próbálja meg szabályozni a sértő beszédet, akkor oda a szólásszabadság eszméje. Ilyen alapon
                            akár ennek a könyvnek a címe is gyűlöletkeltőnek minősülhet Európában. A hatalmon lévők érzékenysége mindig
                            győzni fog.”</span
                        >
                    </div> -->
                    <pw-pressarticle></pw-pressarticle>
                </div>

                <div *ngIf="material?.routerLink === 'morte_taska'" class="parameter-taska-box">
                    <div *ngIf="material?.hasPreorder" class="preorder-text">
                        <div class="preorder">
                            <p>{{ preOrderData }}</p>
                        </div>
                    </div>

                    <div *ngIf="material?.routerLink === 'morte_taska'" class="param-taska-box">
                        <p>{{ 'materials.size' | translate }} {{ material?.dimension }}</p>
                    </div>

                    <div class="text-data-taska-container">
                        <div class="text-taska-data">
                            <p>{{ material?.textData }}</p>
                        </div>
                    </div>

                    <div *ngIf="packageOffer.length > 0" routerLink="/specialoffer/{{ packageOffer[0].routerLink }}" class="package-offer">
                        <div class="christmas-text">
                            <h3>Csomagban, kedvezménnyel!</h3>
                        </div>
                        <img src="{{ packageOfferElement.imageUrl }}" />
                        <div class="package-name">
                            <b>{{ packageOfferElement.packageName }} csomag</b>
                        </div>
                        <div class="package-price">
                            <s>{{ packageOriginalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</s>
                            <span>{{ packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
