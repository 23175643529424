
export const environment = {
    production: false,
    backendOrderUrl: 'https://order.test.multiverzumkiado.hu',
    backendEmailUrl: 'https://email.test.multiverzumkiado.hu',
    backendStoreUrl: 'https://store.test.multiverzumkiado.hu',
    backendAuthUrl: 'https://auth.test.multiverzumkiado.hu',
    backendConfiguratorUrl : 'https://configurator.test.multiverzumkiado.hu',
    apiUrl: 'https://test.multiverzumkiado.hu/',
    googleTagContainerId:'GTM-KFZ49NSJ',
    googleTagId: 'G-564LB0VSS9',
    reCaptchaSiteKey:'6Leup4EpAAAAACfvoLk9cK4NN3WJ6vJb9vx-jB9g',
    reCaptchaSecretKey:'6Leup4EpAAAAAJmqX2QCUtMmer7PwUtxHyhIfJKY',
    downloadUrl:'https://file.test.multiverzumkiado.hu/file/downloadFile'


};
